export const environment = {
  // appUrl: 'https://api-ics.herokuapp.com/', // what's this?
  production: true,

  // production
  _appUrl: 'https://ics-shop-api.azurewebsites.net/',
  _apiIcsShop: 'https://ics-shop-api.azurewebsites.net/api/',
  _apiCmsExchequer: 'https://cms.ics-skills.net/api/exchequer/',
  _apiCmsIcsShop:'https://cms.ics-skills.net/api/icsshop/',
  _shopUrl: 'https://ics.shop.skillsbox.com',

  appUrl: 'https://ics-shop-dev.azurewebsites.net/',
  apiIcsShop: 'https://ics-shop-api.azurewebsites.net/api/',
  apiCmsExchequer: 'https://cms.ics-skills.net/api/exchequer/',
  apiCmsIcsShop:'https://cms.ics-skills.net/api/icsshop/',
  shopUrl: 'https://ics.shop.skillsbox.com'

  



  // // local
  // appUrl: 'http://localhost:5000/',
  // apiIcsShop: 'http://localhost:5000/api/',
  // apiCmsExchequer: 'https://cms.ics-skills.net/api/exchequer/',
  // apiCmsIcsShop:'https://cms.ics-skills.net/api/icsshop/',

};
